import React from 'react'
import styles from '../styles/Services.module.css'
import { Image, Flex, Box } from 'rebass'
import classNames from 'classnames'
import ContactForm from './contactUs/ContactForm'
import { ServicesQuery } from '../../graphql-types'
import { blocksToText } from '../helpers'
import TeamMemberInfo from './TeamMemberInfo'
import PlusIcon from '../components/svg/PlusIcon'
import SectionHeader from './SectionHeader'
import Grid from '@material-ui/core/Grid'
import Eighteen from './svg/Eighteen'
const BlockContent = require('@sanity/block-content-to-react')

interface Props {
  data: ServicesQuery;
}

const Services = ({data}: Props): JSX.Element => {
  const {allSanityTeamMembers: {nodes: teamMembers}, originsText, ourTeamText, sanityServices: {types: services}} = data
  return (
    <div className={styles.container}>
      <h1 className={styles.headerText}>The Team</h1>
      <div className={styles.imageContainer}>
        <Image
          src='/images/our-team.jpg'
          sx={{
            width: '90%',
          }}
        />
      </div>
      <section className={styles.servicesContainer}>
        <SectionHeader dark center>Services we offer</SectionHeader>
        <p className={styles.services}>
          {
            services.map((service: string, i: number) => {
              if (i == services.length - 1) return (<span key={service}>{ service }</span>)
              return (
                <span key={service}>{ service } <span className={styles.serviceSeperator}>//</span> </span>
              )
            })
          }
        </p>
      </section>
      <section className={classNames(styles.originsSection, styles.section)}>
        <Eighteen className={ styles.eighteen }/>
        <Grid container>
          <Grid item sm={ 6 } xs={ 12 }>
            <div className={ styles.originsImage }/>
          </Grid>
          <Grid item sm={ 6 } xs={ 12 }>
            <Flex className={ styles.originsFlex }>
              <div className={ styles.originsContainer }>
                <SectionHeader dark
                               className={ styles.originsHeader }>Origins</SectionHeader>
                <BlockContent className={ styles.originsText }
                              blocks={ originsText?._rawText }/>
              </div>
            </Flex>
          </Grid>
        </Grid>
      </section>
      <section className={styles.ourTeamSection}>
        <SectionHeader center className={styles.ourTeamHeader}>Meet our team</SectionHeader>
        <p className={styles.ourTeamText}>{blocksToText(ourTeamText?._rawText)}</p>
        <Grid container spacing={2}>
          {teamMembers.map((member) => (
            <Grid item sm={ 3 } xs={ 12 } className={ styles.teamGridItem }>
              <TeamMemberInfo teamMember={ member }/>
            </Grid>
          ))}
          <Grid item sm={ 3 } xs={ 12 } className={ styles.teamGridItem }>
            <div className={ styles.teamImageContainer }>
              <div className={ styles.joinTeam }>
                <p className={ styles.teamMemberName }>Your Name</p>
                <p className={ styles.teamMemberTitle }>Join Our Team</p>
                <PlusIcon className={ styles.plus }/>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
      <ContactForm />
    </div>
  )
}

export default Services
