import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export interface Props {
  color?: string;
  className?: string;
}

const Eighteen = ({ color = '#c07531', className }: Props): JSX.Element => {
  return (
    <SvgIcon width="536.16" height="534.96" viewBox="0 0 536.16 534.96"
             fill="none" className={ className }>
      <defs>
        <style>
          {
            `.cls-1{fill:none;stroke:${ color };stroke-miterlimit:10;stroke-width:19px;}.cls-2{fill:${ color };}`
          }
        </style>
      </defs>
      <circle className="cls-1" cx="268.03" cy="267.84" r="257.48"/>
      <path className="cls-2"
            d="M197.51,307.82h-44c1.62-3.26,4.88-12.22,4.88-38V137.32c0-21.73-6.24-31.77-10.86-34.76l44.53-17.37V269.81C192.08,295.6,195.61,304.56,197.51,307.82Z"/>
      <path className="cls-2"
            d="M257.33,396c0-29.86,18.73-47.51,36.38-55.93-13-7.87-28.51-23.62-28.51-48.87,0-34.21,28-58.91,59.19-58.91,30.95,0,59.46,24.7,59.46,58.91,0,25.25-15.48,41-28.51,48.87,17.65,8.42,36.38,26.07,36.38,55.93,0,34.49-29.32,60-67.33,60C286.65,456,257.33,430.49,257.33,396Zm101-4.07c0-19.55-15.21-35.57-33.94-35.57-18.46,0-33.67,16-33.67,35.57,0,19.82,15.21,35.57,33.67,35.57C343.12,427.5,358.33,411.75,358.33,391.93Zm-33.94-67.06c15.2,0,27.15-14.12,27.15-32,0-17.65-12-32-27.15-32-14.93,0-26.88,14.39-26.88,32C297.51,310.75,309.46,324.87,324.39,324.87Z"/>
    </SvgIcon>
  )
}

export default Eighteen
