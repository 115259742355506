import React, { useState } from 'react'
import { SanityTeamMembers } from '../../graphql-types'
import styles from '../styles/TeamMemberInfo.module.css'
import classNames from 'classnames'
import FSDialog from './FSDialog'
import ForwardIcon from './svg/ForwardIcon'
import { Grid, Hidden } from '@material-ui/core'
import NavBar from './NavBar'
import BackArrow from './svg/BackArrow'

const BlockContent = require('@sanity/block-content-to-react')

export interface Props {
  teamMember: Omit<SanityTeamMembers, 'children' | 'internal'>;
}

const TeamMemberInfo = ({ teamMember: { id, image, name, title, _rawBio } }: Props): JSX.Element => {
  const [ open, setOpen ] = useState(false)

  const toggleOpen = (isOpen: boolean) => (): void => {
    setOpen(isOpen)
  }

  return (
    <>
      <div key={ id } className={ styles.teamImageContainer }
           onClick={ toggleOpen(true) }
           style={ { backgroundImage: `url(${ image?.asset?.url })` } }>
        <div
          className={ classNames(styles.teamImageOverlay, styles.hiddenMobile) }>
          <p className={ styles.teamMemberName }>{ name }</p>
          <p className={ styles.teamMemberTitle }>{ title }</p>
          <ForwardIcon className={ styles.icon } circleColor='white'
                       arrowColor='white'/>
        </div>
        <Hidden smUp implementation='css'>
          <p className={ styles.teamMemberName }>{ name }</p>
          <p className={ styles.teamMemberTitle }>{ title }</p>
        </Hidden>
      </div>
      <FSDialog open={ open } handleClose={ toggleOpen(false) } fullScreen
                contentClassName={ styles.dialog }
                closeButtonClassName={ styles.dialogCloseButton }>
        <Hidden smUp implementation='css'>
          <NavBar isDark/>
        </Hidden>
        <Grid container className={ styles.dialogFlex }>
          <Grid item className={ styles.dialogSection } xs={ 12 } sm={ 6 }>
            <h3 className={ styles.dialogMemberName }>{ name }</h3>
            <p className={ styles.dialogMemberTitle }>{ title }</p>
            <div className={ styles.bioRule }/>
            <div className={ styles.bio }>
              <BlockContent blocks={ _rawBio }/>
            </div>
          </Grid>
          <Grid
            xs={ 6 }
            item
            className={ classNames(styles.dialogSection, styles.hiddenMobile, { flexShrink: 1 }) }
          >
            <div className={ classNames(styles.dialogImage) }
                 style={ { backgroundImage: `url(${ image?.asset?.url })` } }/>
          </Grid>
          <div onClick={ toggleOpen(false) }
               className={ classNames(styles.backArrowContainer, styles.hiddenWeb) }>
            <BackArrow className={ styles.backArrow }/>
          </div>
        </Grid>
      </FSDialog>
    </>
  )
}

export default TeamMemberInfo
