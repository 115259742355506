import React from 'react';
import '../styles/index.css';
import Services from "../components/Services";
import PageLayout from "../components/PageLayout";
import {graphql} from "gatsby";
import { ServicesQuery } from '../../graphql-types'

interface Props {
  data: ServicesQuery;
}

const About = ({data}: Props) => {
  return (
    <PageLayout title='About | FS Woodworks'><Services data={data} /></PageLayout>
  );
}

export const query = graphql`
  query services {
    allSanityTeamMembers(sort: {order: ASC, fields: order}) {
      nodes {
        id
        name
        title
        image {
          asset {
            url
          }
        }
        _rawBio(resolveReferences: {maxDepth: 2})
      }
    }
    originsText: sanityCopy(name: {eq: "Origins"}) {
      _rawText(resolveReferences: {maxDepth: 2})
    }
    ourTeamText: sanityCopy(name: {eq: "Our Guys"}) {
      _rawText(resolveReferences: {maxDepth: 2})
    }
    sanityServices {
      types
    }
  }
`;

export default About;
