import React from 'react'
import { SvgIcon } from '@material-ui/core'

export interface Props {
  color?: string;
  className?: string;
}

const BackArrow = ({ className, color = 'white', ...props }: Props): JSX.Element => {
  return (
    <SvgIcon width="43.12" height="17.85" viewBox="0 0 43.12 17.85" fill="none"
             className={ className }>
      <path fill={ color }
            d="M34,.07,32.88,1.16a.22.22,0,0,0,0,.32L39,7.58a.23.23,0,0,1-.16.39H.23A.22.22,0,0,0,0,8.19V9.74A.23.23,0,0,0,.23,10H38.86a.22.22,0,0,1,.16.38l-6,6a.23.23,0,0,0,0,.31l1.1,1.1a.22.22,0,0,0,.32,0l8.63-8.63a.22.22,0,0,0,0-.32L34.29.07A.22.22,0,0,0,34,.07Z"/>
    </SvgIcon>

  )
}

export default BackArrow
